@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Montserrat';
    src: url(./fonts/Montserrat/Montserrat-Regular.ttf);
    font-weight: 500;
}
@font-face {
    font-family: 'Montserrat';
    src: url(./fonts/Montserrat/Montserrat-Black.ttf);
    font-weight: 900;
}
@font-face {
    font-family: 'Montserrat';
    src: url(./fonts/Montserrat/Montserrat-ExtraBold.ttf);
    font-weight: 800;
}
@font-face {
    font-family: 'Montserrat';
    src: url(./fonts/Montserrat/Montserrat-Bold.ttf);
    font-weight: 700;
}

body {
    background-color: #0E0D18;
    overflow-x: hidden;
}

* {
  scroll-behavior: smooth !important;
}

.md\:h-32 {
  position: absolute;
  width: 96px;
  height: 88px !important;
  left: 40px;
}

.txt{
font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 24px;
letter-spacing: 2px;
color: #65D9B2;
}

.mint-txt{
  color: #BFBFBF;
  text-align: center;
  font-weight: 300;
  font-size: 20px;
}

.btn {
  box-sizing: border-box;
  position: absolute;
  width: 171px;
  height: 55px;
  left: 655px;
  top: 226px;
  background: linear-gradient(270deg, #903EF7 0%, #387B96 100%);
  border: 1px solid #000000;
  border-radius: 12px;
}


.btn-left{
  box-sizing: border-box;
  position: absolute;
  width: 171px;
  height: 55px;
  left: 33px;
  top: 375px;
  background: linear-gradient(270deg, #903EF7 0%, #387B96 100%);
  border: 1px solid #000000;
  border-radius: 12px;
}

.btn1{
box-sizing: border-box;
position: absolute;
width: 178px;
height: 57px;
left: 866px;
top: 516px;
background: linear-gradient(270deg, #903EF7 0%, #387B96 100%);
border: 1px solid #000000;
border-radius: 12px;
}

.btn2{
  box-sizing: border-box;
  position: absolute;
  width: 178px;
  height: 57px;
  left: 866px;
  top: 456px;
  background: linear-gradient(270deg, #903EF7 0%, #387B96 100%);
  border: 1px solid #000000;
  border-radius: 12px;
  }

  .btnIn {
    box-sizing: border-box;
    position: absolute;
    width: 171px;
    height: 55px;
    background: linear-gradient(270deg, #903EF7 0%, #387B96 100%);
    border: 1px solid #000000;
    border-radius: 12px;
  }

.util-btn{
  box-sizing: border-box;
  position: absolute;
  width: 178px;
  height: 57px;
  left: 38px;
  top: 496px;
  background: linear-gradient(270deg, #903EF7 0%, #387B96 100%);
  border: 1px solid #000000;
  border-radius: 12px;
  }

.timeline {
    list-style: none;
    margin: 200px 0 0;
    border-top: var(--thickness) solid white;
    display: table;
    border-spacing: 30px 0;
    transform: translateY(200px);
    padding-left: 600px;
  }
  
  
  .timeline li {
    display: table-cell;
    position: relative;
    min-width: 200px;
  }
  
  
  .circle {
    margin: 0 auto;
    top: -24px;
    left: 0;
    right: 0;
    width: 50px;
    height: 50px;
    background-color: #01060F;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
  }
  
  
  .inner-circle {
    width: 30px;
    height:30px;
    border: var(--thickness) solid #2CAEC3;
    border-radius: 50%;
  }
  
  .content {
    text-align: center;
    position: relative;
    
  }
  .avatar {
    width: 20px;
    height: 20px;
    display: block;
    border-radius: 50%;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    text-align: center;
    line-height: 30px;
    background: #fffff0;
  }
  .content:before {
    content: '';
    width: 50px;
    height: var(--height);
    left: 50%;
    margin-left: -2px;
    position: absolute;
  }
  
  li.down .avatar {
    top: 80px;
    left: 100px;
  }
  
  li.down .content:before {
    border-left: var(--thickness) solid white;
  }
  
  li.up .avatar {
    background: #fffff0;
    margin-top: -20px;
  }
  
  li.up .content {
    margin-top: calc(var(--height) * -1);
  }
  
  li.up .content:before {
    border-left: var(--thickness) solid white;
  }

  .intro{
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .about{
    margin-bottom: 20px;
  }
  .eco{
    padding-top: 115px;

  }

  .contain{
    height: 238px;
  }
  .wid{
    width: 593px;
  }
  .box {
    width: 572px !important;
    height: 560px;
    padding: 1em;
    display: flex;
    flex-direction: column;
    color: antiquewhite;
    justify-content: center;
    background: #0B142F;
    border-radius: 18px;
}
.box2 {
  width: 572px !important;
  height: 560px;
  padding: 1em;
  display: flex;
  flex-direction: column;
  color: antiquewhite;
  justify-content: center;
  background: #ffffff00;
  border-radius: 18px;
}

.box3 {
  /* width: 572px !important; */ /* Remove this line */
  padding: 1em;
  display: flex;
  flex-direction: column;
  color: antiquewhite;
  justify-content: center;
  background: #0B142F;
  border-radius: 18px;
  max-width: 90%; /* Or any percentage or pixel value */
  margin: 0 auto; /* Center the box */
}


.card1, .card2, .card3 {
  box-sizing: border-box;
  width: 258px !important;
  height: 170px;
  border-radius: 25px;
  border: 2px solid #7658E3;
}

/* Apply position and specific left/top only on larger screens */
@media (min-width: 1868px) {
  .card1 {
    position: absolute;
    left: 639px;
    top: 114px;
  }

  .card2 {
    position: absolute;
    left: 639px;
    top: 395px;
  }

  .card3 {
    position: absolute;
    left: 952px;
    top: 174px;
  }
}

/* Adjust for mobile screens */
@media (max-width: 1867px) {
  .card1, .card2, .card3 {
    position: relative; /* Reset to relative or static */
    left: auto;         /* Remove the left positioning */
    top: auto;          /* Remove the top positioning */
    margin: 0 auto;     /* Center the card horizontally if needed */
  }
}

.card-image{
  position: absolute;
    top: 20px;
    left: 20px;
    width: 45px;
}

.box-heading{
font-family: 'Montserrat';
text-align: center;
font-style: normal;
font-weight: 600;
font-size: 36px;
line-height: 111.5%;
/* or 40px */
padding: 20px;
color: #fffff0;
}

.box-txt{
font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 154%;
/* or 25px */
text-align: center;
color: #BFBFBF;
}  

.apeInfo .mintData {
  display: flex;
  width: 443px;
  height: 75px;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: #0B1123;
  border-radius: 10px;
  margin-bottom: 0.75rem;
  margin-top: 20px;
}

.progress {
  position: relative;
  width: 210px;
  height: 30px;
  margin: 10px;
  left: 48px;
  background: linear-gradient(270deg, #8347E9 0%, #3F769D 100%);
  border-radius: 5px;
  overflow: hidden;
}

.tippy-box {
  font-size: 0.875rem;
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  padding-left: 0.4rem;
  padding-right: 0.2rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;

}

.progress__fill {
  width: 0%;
  height: 100%;
  background: #3F769D;
  transition: all 0.2s;
}

.progress__text {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  font: bold 14px "Quicksand", sans-serif;
  color: #ffffff;
}
