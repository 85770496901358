input, textarea {
    color: white; /* Default text color */
  }
  
  input::placeholder, textarea::placeholder {
    color: rgba(255, 255, 255, 0.7); /* Lighter color for placeholder text */
  }
  
  input:focus, input:active, textarea:focus, textarea:active {
    color: white; /* Ensure text remains white when the input is focused or active */
  }